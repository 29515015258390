import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/element-variables.scss'
import hljs from 'highlight.js';
import 'highlight.js/styles/a11y-dark.css'
//导入全局样式表
import'./assets/css/global.css'

//创建v-highlight全局指令
Vue.directive('hljs',function (el) {
  let blocks = el.querySelectorAll('pre code');
  blocks.forEach((block)=> {
    hljs.highlightElement(block)
  })
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
